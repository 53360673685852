import React from "react";
import {
    Box,
    Typography,
    Grid,
    Link,
    Card,
    CardContent,
    CardMedia,
} from "@mui/material";
import servicesImg from '../../../Media/services.png';
import { truncateText } from "../../../Components/truncateText";

const projects = [
    {
        title: "Project 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum",
        image: servicesImg,
        link: "#",
    },
    {
        title: "Project 2",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum",
        image: servicesImg,
        link: "#",
    },
    {
        title: "Project 3",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum",
        image: servicesImg,
        link: "#",
    },
    {
        title: "Project 4",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nulla auctor, vestibulum",
        image: servicesImg,
        link: "#",
    },
];

export default function SpecialProjects() {
    return (
        <Box
            sx={{
                backgroundColor: "#edf7fe",
                py: 8,
                px: 2,
                color: "#1c2b78",
            }}
        >
            {/* <Typography
                variant="h6"
                textAlign="center"
                mb={1.5}
                sx={{ color: "#008bf9" }}
            >
                Explore Projects
            </Typography> */}
            <Typography
                variant="h4"
                textAlign="center"
                mb={6}
                sx={{ fontWeight: "bold" }}
            >
                Special Projects
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {projects.map((service, idx) => (
                    <Grid item xs={12} sm={6} md={4} lg={2.2} key={idx}>
                        <Card
                            sx={{
                                maxWidth: "100%",
                                backgroundColor: "#fff",
                                borderRadius: 0,
                                color: "#1c2b78",
                                height: { xs: "390px", md: "320px" }
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={service.image}
                                alt={service.title}
                            />
                            <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1, mb: 2, color: '#666666' }}>
                                    {truncateText(service?.description, 55)}
                                </Typography>
                                <Link href={service.link} color="inherit" underline="none">
                                    <Typography variant="body2" sx={{ color: "#008bf9" }}>
                                        Read More →
                                    </Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
