import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import AwardsCard from "./AwardsCard";
import AwardBanner from "./AwardBanner";

const AwardPage = () => {
    const [awards, setAwards] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/awards/getall"
                );
                setAwards(response?.data?.result);
            } catch (error) {
                console.error("Error fetching awards:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAwards();
    }, []);

    const renderAwards = (receivedBy) => {
        return awards
            .filter((award) => award.Received_by === receivedBy)
            .map((award, index) => (
                <AwardsCard key={award?.Award_id} docUrl={docUrl} item={award} />
            ));
    };

    return (
        <>
            <AwardBanner />
            <Box
                sx={{
                    maxWidth: "100%",
                    paddingBottom: "50px",
                    backgroundColor: "#edf7fe",
                }}
            >
                <Typography
                    variant="h1"
                    backgroundColor="#1c2b78"
                    color="white"
                    textAlign={"center"}
                    fontSize={{ xs: "2rem", md: "2.5rem", lg: "3.5rem" }}
                    fontWeight={"600"}
                    mt={"1.8rem"}
                    py="3.5rem"
                >
                    Awards received by STP
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : (
                    <Grid
                        container
                        mt="6rem"
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {renderAwards("Science and Technology Park")}
                    </Grid>
                )}

                <Typography
                    variant="h1"
                    textAlign={"center"}
                    backgroundColor="#1c2b78"
                    color="white"
                    fontSize={{ xs: "2rem", md: "2.5rem", lg: "3.5rem" }}
                    fontWeight={"600"}
                    mt={"1.8rem"}
                    py="3.5rem"
                >
                    Awards received by Incubatees
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : (
                    <Grid
                        container
                        mt="6rem"
                        justifyContent={"center"}
                        // alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {awards
                            .filter(
                                (award) => award.Received_by !== "Science and Technology Park"
                            )
                            .map((award, index) => (
                                <AwardsCard
                                    key={award?.Award_id}
                                    docUrl={docUrl}
                                    item={award}
                                />
                            ))}
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default AwardPage;
