import React from 'react'
import Content from './content';
import Banner from '../../Components/Banner';

const GeoinformaticsPage = () => {
    return (
        <>
            <Banner title="Geoinformatics" />
            <Content />
        </>
    )
}

export default GeoinformaticsPage;