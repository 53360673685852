import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Link, Card, CardContent } from "@mui/material";
import AssociationDetail from "../AssociationDetail/AssociationDetail";
import spotlight from "../../../Media/spotlight.png";
import announcement from "../../../Media/announcement.png";
import axios from "axios";

const announcementItems = [
  {
    category: "Announcements",
    Title: "विज्ञान आणि तंत्रज्ञान उद्यानात आरटी",
    description: [
      "उद्यानातील नवीन आणि उर्जित फोकस पूर्ण झाला आहे. या प्रकल्पांमध्ये उद्योजकांना ५०% पेक्षा जास्त भागासाठी जागा.",
      "उद्यानातील नवीन आणि उर्जित फोकस पूर्ण झाला आहे. या प्रकल्पांमध्ये उद्योजकांना ५०% पेक्षा जास्त भागासाठी जागा.",
      "उद्यानातील नवीन आणि उर्जित फोकस पूर्ण झाला आहे. या प्रकल्पांमध्ये उद्योजकांना ५०% पेक्षा जास्त भागासाठी जागा.",
      "उद्यानातील विज्ञान आणि तंत्रज्ञान उद्यानात नव्या तंत्रज्ञानाच्या उर्जित फोकस पूर्ण झाला आहे. या प्रकल्पांमध्ये उद्योजकांना जागा.",
    ],
    Images: [
      "news/COVID-19 Vaccination Drive Begins in Major Cities/1717406608137news.jpeg",
      "news/COVID-19 Vaccination Drive Begins in Major Cities/1717687929862banner.jpeg"
    ],
  },
];

export default function NewsAnnouncements() {
  const [spotlightItems, setSpotlightItems] = useState([]);
  const [announcementItems, setAnnouncementItems] = useState([]);

  useEffect(() => {
    const fetchSpotlightData = async () => {
      try {
        const response = await axios.get(
          "https://api.scitechpark.org.in/news/getall"
        );
        const latestNews = response?.data?.result.slice(0, 5);
        setSpotlightItems(latestNews);
      } catch (error) {
        console.error("Error fetching spotlight data", error);
      }
    };

    fetchSpotlightData();

    const fetchAnnouncementData = async () => {
      try {
        const response = await axios.get(
          "https://api.scitechpark.org.in/announcement/getall"
        );
        console.log(response.data); 
        const latestAnnouncements = response?.data?.result.slice(0, 5); 
        setAnnouncementItems(latestAnnouncements);
      } catch (error) {
        console.error("Error fetching announcement data", error);
      }
    };

    fetchAnnouncementData();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#edf7fe", py: 8, px: 2 }}>
      {/* <Typography
        variant="h6"
        textAlign="center"
        mb={1.5}
        sx={{ color: "#008bf9" }}
      >
        Spotlight & Announcement
      </Typography> */}
      <Typography
        variant="h4"
        textAlign="center"
        mb={6}
        sx={{ fontWeight: "bold", color: "#1c2b78" }}
      >
        News & Announcements
      </Typography>

      {/* Spotlight Carousel Section */}
      <Grid container spacing={4} justifyContent="center">
        {spotlightItems.length > 0 && (
          <Grid item>
            <Card
              sx={{
                boxShadow: 3,
                width: { xs: "400px", md: "530px" },
                height: "535px",
                borderRadius: "0",
              }}
            >
              <CardContent sx={{ padding: "0rem" }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  textAlign="center"
                  sx={{
                    backgroundColor: "#1c2b78",
                    color: "white",
                    padding: "0.6rem",
                  }}
                >
                  News & Media
                </Typography>

                <AssociationDetail
                  spotlightItems={spotlightItems}
                />

                <Box sx={{ padding: "0.6rem" }}>
                  <ul>
                    {spotlightItems.slice(0, 5).map((newsItem, idx) => (
                      <li key={idx}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666666",
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem",
                            letterSpacing: "0.8px",
                            margin: "0.5rem 0",
                            textJustify: ''
                          }}
                        >
                          {newsItem.Title}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Link href="/news-and-media" color="inherit" underline="none" >
                  <Typography
                    variant="body2"
                    sx={{ color: "#0275d8", m: "0 2.2rem" }}
                  >
                    View More →
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Announcement Section */}
        {announcementItems.length > 0 && (
          <Grid item>
            <Card
              sx={{
                boxShadow: 3,
                width: { xs: "400px", md: "530px" },
                height: "535px",
                borderRadius: "0",
              }}
            >
              <CardContent sx={{ padding: "0rem" }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  textAlign="center"
                  sx={{
                    backgroundColor: "#1c2b78",
                    color: "white",
                    padding: "0.6rem",
                  }}
                >
                  Announcements
                </Typography>

                <AssociationDetail
                  spotlightItems={announcementItems}
                />

                <Box sx={{ padding: "0.6rem" }}>
                  <ul>
                    {announcementItems.slice(0, 5).map((newsItem, idx) => (
                      <li key={idx}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666666",
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem",
                            letterSpacing: "0.8px",
                            margin: "0.5rem 0",
                            textJustify: ''
                          }}
                        >
                          {newsItem.Title}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Link href="/announcements" color="inherit" underline="none" >
                  <Typography
                    variant="body2"
                    sx={{ color: "#0275d8", m: "0 2.2rem" }}
                  >
                    View More →
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
