import React from 'react'
import ContactForm from './ContactForm'
import { Box } from '@mui/material'

const ContactUsPage = () => {
    return (
        <Box sx={{ backgroundColor: "#edf7fe", py: { xs: 1, md: 2, lg: 8 } }}>
            <ContactForm />
        </ Box>
    )
}

export default ContactUsPage;