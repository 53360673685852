import { Box } from "@mui/material";
import React from "react";
import BusinessIncubationContent from "../HomePage/BusinessIncubation/BusinessIncubationContent";

const Content = () => {
    return (
        <Box sx={{ py: 12, backgroundColor: "#edf7fe", color: "#1C1F35" }}>
            <BusinessIncubationContent />
        </Box>
    );
};

export default Content;
