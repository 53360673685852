import React from 'react';
import { Box, Grid, Typography, Button, Tab, Tabs } from '@mui/material';
import OverviewImage from "../../Media/OverviewImage.png";
import pattern from "../../Media/Pattern.png";
import CustomTabs from './CustomTabs';

export default function CompanyOverview() {

    return (
        <Box sx={{ backgroundColor: '#edf7fe', px: 16, py: 4, pb: '6rem' }}>
            <Grid container spacing={12} alignItems="center">
                <Grid item xs={4} md={6}>
                    <Box sx={{ position: 'relative' }}>
                        <img
                            src={OverviewImage}
                            alt="OverviewImage"
                            // style={{ width: { xs: '30rem', md: '80rem' } }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid display={"flex"}>
                        <img height="28px" src={pattern} alt="pattern" />
                        <Typography
                            variant="h6"
                            backgroundColor="#eaeff3"
                            fontSize={"1rem"}
                            fontWeight="500"
                            px="0.5rem"
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Typography variant="h4" sx={{ mt: 2, color: "1C1F35", fontWeight: 'bold' }}>
                        Our Company Overview
                    </Typography>
                    <Typography sx={{ mt: 1.5, color: '#666C89' }}>
                        Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative
                        thinking to further the overall value proposition.
                    </Typography>

                    <CustomTabs />

                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            backgroundColor: "#091242",
                            color: "white",
                            padding: "1rem 3.4rem",
                            fontWeight: 550,
                            fontSize: "14px",
                            textTransform: "none",
                            borderRadius: 0,
                            ":hover": {
                                backgroundColor: "#5A6C9D",
                            },
                        }}
                    >
                        Learn More
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
