import React, { useState, useEffect } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const dummyData = [
  {
    Title: "Tehri Acro Festival 2023",
    Description: "Discover an unparalleled journey filled with adventure",
    Url: "#",
  },
  {
    Title: "Adventure Awaits",
    Description: "Join us for the most thrilling experience of your life",
    Url: "#",
  },
];

export default function BannerSection() {
  const [carouselItems, setCarouselItems] = useState([] || dummyData);
  const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

  useEffect(() => {
    axios
      .get("https://api.scitechpark.org.in/banner/getall")
      .then((response) => {
        setCarouselItems(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching the carousel items:", error);
      });
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        minHeight: { xs: "50vh", lg: "75vh" },
      }}
    >
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop
        autoPlay
        interval={5000}
        stopOnHover
        showStatus={false}
      >
        {carouselItems
          ?.filter((event) => event.Is_deleted === 0)
          ?.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundImage: `url(${docUrl}${item?.Image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                padding: {
                  xs: "14rem 1rem",
                  md: "15rem 0 2rem 15rem",
                },
                minHeight: "75vh",
                color: "white",
                position: "relative",
                "::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.3)", 
                  zIndex: 1,
                },
              }}
            >
              <Grid item xs={12} md={6} sx={{ zIndex: 2 }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={{ xs: "start", md: "start" }}
                >
                  <Typography
                    variant="body2"
                    letterSpacing={"1.5px"}
                    mb={2}
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }} 
                  >
                    {item.Description}
                  </Typography>
                  <Typography
                    variant="h1"
                    textAlign="left"
                    fontSize={{ xs: "2rem", md: "3rem" }}
                    fontWeight={"600"}
                    letterSpacing={"1.5px"}
                    sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }} 
                  >
                    {item.Title}
                  </Typography>
                  <Button
                    sx={{
                      backgroundColor: "#008bf9",
                      color: "white",
                      fontSize: "0.6rem",
                      fontWeight: "600",
                      padding: "0.5rem 1rem",
                      width: "6rem",
                      borderRadius: "10px",
                      position: "relative",
                      overflow: "hidden",
                      mt: 3,
                      "&:hover": {
                        backgroundColor: "#009bf9",
                      },
                      zIndex: 2,
                    }}
                    size="large"
                    href={item.Url}
                    target="_blank"
                  >
                    Explore <TrendingFlatIcon sx={{ width: "auto" }} />
                  </Button>
                </Box>
              </Grid>
            </Box>
          ))}
      </Carousel>
    </Box>
  );
}
