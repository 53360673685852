import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import axios from "axios";
import AnnouncementBanner from "./AnnouncementBanner";
import pattern from "../../Media/Pattern.png";
import AnnouncementCard from "./AnnouncementCard";

const AnnouncementPage = () => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/announcement/getall"
                );
                setAnnouncements(response?.data?.result);
            } catch (error) {
                console.error("Error fetching announcements:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAnnouncements();
    }, []);

    return (
        <>
            <AnnouncementBanner count={announcements?.length} />
            <Box
                sx={{
                    py: 10,
                    backgroundColor: "#edf7fe",
                }}
            >
                <Grid display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <img height="32px" src={pattern} alt="pattern" />
                    <Typography
                        variant="h6"
                        backgroundColor="#eaeff3"
                        fontWeight="500"
                        px="0.2rem"
                    >
                        Latest Announcements
                    </Typography>
                </Grid>
                <Typography
                    textAlign="center"
                    variant="h4"
                    fontWeight="700"
                    color="#1C1F35"
                    p={2}
                >
                    Our Latest Announcements
                </Typography>
                {loading ? (
                    <Typography textAlign="center" mt={2}>
                        Loading...
                    </Typography>
                ) : (
                    <Grid
                        container
                        mt="3rem"
                        justifyContent={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "2rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {announcements.map((item, index) => (
                            <AnnouncementCard
                                key={item?.Announcement_id}
                                docUrl={docUrl}
                                item={item}
                            />
                        ))}
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default AnnouncementPage;
