import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Image from "../../Media/bgImage.png";

const BlogBanner = ({ count }) => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: { xs: "60vh", md: '35vh', lg: '70vh' },
                backgroundImage: `url(${Image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                color: "white",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2rem", md: "3.5rem", lg: "7rem" }}
                    fontWeight="600"
                >
                    News and Media
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                    <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                News and Media count
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                {count}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={10} md={2.8}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Read Every Month
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                1000+
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={2.5}>
                        <Box
                            sx={{
                                backgroundColor: "white",
                                p: 2,
                                borderRadius: 1,
                                textAlign: "center",
                                color: "1C2B78",
                            }}
                        >
                            <Typography fontSize={"1rem"} fontWeight="600" color="#008bf9">
                                Editors
                            </Typography>
                            <Typography fontSize={"2.5rem"} fontWeight="700" color="#1C2B78">
                                30+
                            </Typography>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
};

export default BlogBanner;
