import { Box, Container, Typography } from "@mui/material";
import React from "react";

const Content = () => {
    return (
        <Box sx={{ py: 6, backgroundColor: "#edf7fe", color: "#1C1F35" }}>
            <Container >
                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Geospatial Mapping Solutions
                </Typography>
                <Typography variant="body1" paragraph>
                    Scitech Park provides Geospatial Mapping Solutions to different
                    government, semi-government, and private organisations with the help of
                    a dedicated team of experienced GIS professionals. Our expertise ranges
                    from handling very high-resolution satellite images to conducting
                    GIS-based spatial analysis. Our geospatial umbrella covers GIS for Urban
                    and Rural Planning, archival data digitization, time variance analysis
                    using multi-temporal satellite images, and risk-based GIS modelling. We
                    excel in adopting and promoting Open Source GIS technologies and have
                    created our own product, the Open PRP Suite.
                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Earth Watch
                </Typography>
                <Typography variant="body1" paragraph>
                    Since its conception, satellite imagery has played a crucial role in allowing us to comprehend intricate ecological interactions around us. By performing inventory and mapping of the Earth’s surface, we can recognize concerns and threats to vital resources like forests, water, soil, minerals, and land reserves. When accurately mapped, effective planning and resource utilization can take place with the help of this data.
                    <br />
                    Scitech Park Pune actively engages in diverse projects encompassing impact assessment, temporal change analysis, resource mapping for regional development planning, habitat studies for keystone species, and geospatial modelling in order to properly appreciate the intricate ecological exchanges. We maintain a close collaboration with the State Government of Assam across different levels, employing geospatial technologies to target intervention strategies in rice-fallow and stress-prone areas as well as  the development of a geospatially informed decision support system at the village level known as Gaon Panchayat Resource Information System (GPRIS).

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    City Gas Network Mapping
                </Typography>
                <Typography variant="body1" paragraph>
                    Scitech Park’s knowledge and skill in the domain of City Gas Network Mapping, underscored by our exceptional delivery and unwavering client satisfaction, has propelled us to the forefront of India's City Gas Distribution Mapping sector. Our distinct approach involves comprehensive surveying, mapping, consulting services, and the use of a standardised mapping platform through open-source tools and technologies. This singular model is designed to resonate with CGD companies, empowering them to embrace our tailored solutions and disruptive pricing strategy.
                    <br />
                    Our expertise in this field revolves around ground mapping of Gas Assets, with the help of cutting edge techniques such as Electronic Total Station (ETS) and Differential Global Positioning System (DGPS) Survey, alongside other CGPS-specific strategies. This technology has facilitated the development of desktop, mobile, and web GIS solutions. Notably, we have pioneered India's first indigenous geospatial application in the CGD sector, leveraging open-source tools and technologies.
                    <br />
                    With immense pride, we have enabled the geospatial transformation of 14 CGD Companies spanning over 2 lakh sq. km. of geographic expanse. Our contributions include more than 9000 km of Gas Pipelines, 8 lakhs+ domestic customers, and around 10,000+ industrial and commercial connections, all aligned with PNGRB compliances for CGD companies.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Unmanned Aerial Vehicle (UAV) Imaging
                </Typography>
                <Typography variant="body1" paragraph>
                    The advent of Very High-Resolution Imaging has helped unlock the potential within the field of earth observation, providing insight into many things about our geography, nature and natural surroundings. Unmanned Aerial Vehicles with the power of vertical take-off and landing is one piece of technology under this category that is now used in urban and rural administration, aiding in categorically demarcating lands with precision, and mapping utility features with least physical hindrance.
                    <br />
                    Scitech Park, Pune has undertaken a series of assignments in the realm of land survey and land record mapping. These assignments deal with diverse domains, ranging from property-tax assessment to meticulous demarcation of authorised / unauthorised buildings or structures. We also have expertise in utility and asset mapping for vital infrastructure such as roads, railways, and electrical installations. Moreover, we have engaged in volumetric assessments of mining sites, delivering precise ortho-rectified images for designated areas of interest, complete with digital elevation and surface models. These images are derived from ultra-high-resolution sources via UAV.
                    <br />
                    One of Scitech Park, Pune’s principal endeavours has been the pioneering exploration of UAV Imaging’s frontiers. This has culminated in groundbreaking research involving automatic anomaly detection in power transmission towers, facilitated by advanced deep learning algorithms. The synergistic capabilities of UAV imaging and deep learning have led to the automation of tower inspections, a reduction in the likelihood of accidents or human injuries, and an expedited anomaly detection process. It has also significantly minimised electricity outage durations, emphasising the park's commitment to technological advancement alongside safety enhancement.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Urban Mapping
                </Typography>
                <Typography variant="body1" paragraph>
                    Geospatial Technology plays a pivotal role in both urban and rural mapping, furnishing planners with near real-time insight into features, resources, and image derivatives. Hence, it has become an important part of Planning and Policy Implementation.
                    <br />
                    In order to foster smart, vibrant and healthy communities in emerging urban zones, meticulous planning is indispensable. Scitech Park, Pune contributes by employing GIS-based planning strategies, facilitating effective land resource management. Our approach involves judicious land use allocation, aligned with development plan reservations, all underpinned by transparent procedures for asset acquisition and ownership rights.
                    <br />
                    In close collaboration with various Urban Local Bodies, we have employed smooth end-to-end GIS technology, wherein we  meticulously ground-map municipal assets such as water, engineering, property tax and sanitation, integrating the same into the GIS Framework. We have also partnered closely with ULBs to devise comprehensive strategies for building "slum-free cities".
                    <br />
                    Furthermore, we have engaged with the Town Planning and Valuation Department, Govt of Gujarat to digitize, digitally convert and visualize the Town Planning Schemes through Web GIS for enhanced mapping accuracy. This approach  ensures our clients the utmost public domain transparency.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Industrial Area Infra-Resource Mapping
                </Typography>
                <Typography variant="body1" paragraph>
                    Leveraging Geospatial Technology alongside advanced analytics has facilitated the seamless transition from paper-based to digital records for industrial estates. This transformation has instilled transparency across departments, most notably Engineering, Accounts, and Allotment. Embracing such technology, we've achieved significant success in recovering outstanding payments from defaulting industries.
                    <br />
                    The implementation of GIS Mapping has yielded a comprehensive understanding of the ground reality, helping identify encroachment and mis-alignments. This insight has aided administrative decisions, allowing for regularization based on reliable satellite image analytics evidence. This data has proven instrumental in detecting plot-level changes through change detection techniques.
                    <br />
                    Scitech Park, Pune has pioneered the development of a customizable web GIS-based Decision Support System for 240 industrial estates in Gujarat. This platform showcases an inventory of industrial estates' plots, with real-time insights into dues, plot transactions, and payment schedules for the Accounts Department. Moreover, we integrate Engineering Department's asset data on the GIS platform, providing dynamic temporal analysis through a web GIS interface. This amalgamation of features aids in the formulation of robust strategies, contributing to a more conducive environment for business operations.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Risk and Hazard Mapping
                </Typography>
                <Typography variant="body1" paragraph>
                    Both in urban and rural parts of India, natural disasters and their resulting aftermath has caused huge loss to the country. Due to increasingly heavy monsoons, one of the major factors affecting various parts of India on an annual basis are floods and unpredictable heavy rains. While we may not control such natural disasters, we can plan and equip ourselves to reduce and minimize loss to life and property. Spatial technology caters to this much needed demand. This technology pre-equips people with  time, extent and expected intensity of damage, further helping achieve the targeted approach of rescue and relief measures.
                    <br />
                    Scitech Park, Pune has conducted many risk and hazard mapping activities  for rainfall induced dam breach and modelled outputs to define time wise extent and intensity of damage.  Scitech Park, Pune has conducted many risk and hazard mapping activities  for rainfall induced dam breach and modelled outputs to define time wise extent and intensity of damage. Through collaborative efforts between Scitech Park, the National Insurance Academy (NIA) in Pune, and General Insurance Company Re (GIC Re) in Mumbai, a significant milestone was achieved. Together, we developed a practical blueprint that empowers insurance underwriters to better assess industrial insurance policies based on flood occurrence and the vulnerability of specific areas within Gujarat's industrial region. This has helped define risk zones, thereby helping insurers to fix premiums based upon the flood frequency factor.
                    <br />
                    Additionally, we actively participated in producing near real-time flood inundation maps on Web GIS (down to the village level) during a flood event for a district in Maharashtra.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    E-Governance
                </Typography>
                <Typography variant="body1" paragraph>
                    With the help of geospatial technology, Scitech park has developed an open-source GIS based decision support system for e-governance which takes into account the existing work flow within different urban local bodies. Our technology provides solutions for citizen centric services, expenditure control, administrative efficiency, income management and online building permissions. We have helped to integrate about 15 inter-operative models across different departments that can seamlessly provide on the fly decision support. Scitech park has successfully implemented these e-governance solutions for Surat, Rajokot and Pune (PCMC). We also provide consultancy by provisioning e-governance implementation plan in the form of a detailed project.
                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Precision Survey
                </Typography>
                <Typography variant="body1" paragraph>
                    Scitech Park specialises in providing highly accurate topographic surveying services for urban and rural areas, encompassing both greenfield and brownfield infrastructure projects, as well as urban planning initiatives. Our clientele includes municipal corporations, area development authorities, regional development authorities, industrial development corporations, and port land authorities.
                    <br />
                    Within this field, we harness cutting-edge surveying technologies such as unmanned aerial vehicles (UAVs), differential global positioning systems (DGPS), and electronic total station machines (ETS), all operated by our dedicated and sector-specific survey associates, as well as accredited agencies and incubatees with extensive experience in the field.
                    <br />
                    Among our distinguished clients who have benefited from our precise surveying services are organisations such as the Kakatiya Urban Development Authority (KUDA), the V.O Chidambaranar Port Authority in Tuticorin, the Surat Urban Development Authority (SUDA), the Southern Command under the Ministry of Defense in Ahmedabad, the Gandhinagar Urban Development Authority, and various municipal corporations, including Bhavnagar and Rajkot.

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    Software Development
                </Typography>
                <Typography variant="body1" paragraph>
                    Our in-house geospatial software development team is actively involved in the creation of various Enterprise Resource Planning (ERP) applications aimed at enhancing effective management and facilitating informed decision-making.
                    <br />
                    Our team has successfully developed the "Suvidya" School Management System for the Directorate of Defence Estate (DGDE), Pune, a division under the Ministry of Defence, Government of India. This system serves 62 cantonment boards, covering 194 schools and benefiting 61,943 students and 1,965 school teachers. As part of the Digital India Initiative, this platform enables seamless connectivity across cantonment board schools, students, teachers, and parents. The system can be utilized for tasks such as centralized admissions, monitoring student progress, submitting and evaluating online assignments, managing complaints, facilitating fee collection, and issuing online transfer certificates. To ensure widespread accessibility, the system has been developed with multilingual support, including languages such as English (as the default), Hindi, Marathi, Gujarati, Bengali, Tamil, and Kannada.
                    <br />
                    In addition to the above, our team is actively engaged in developing isolated modules for utility inspection, preventive maintenance, and a gas sales and purchase module (GSPR).

                </Typography>

                <Typography variant="h4" fontWeight="600" gutterBottom>
                    E-Governance Based IT Services to Government Agencies
                </Typography>
                <Typography variant="body1" paragraph>
                    Scitech Park, Pune specialises in the provision of  software development services to various govt entities at both the state and central level, catering to critical operational needs. Our portfolio includes the creation of web-based applications designed to facilitate fair and impartial candidate scrutiny and selection processes for different govt positions. Notable clients in this regards comprise: the Cantonment Board of Kirkee, Pune Cantonment Board, Centre for Materials for Electronics Technology (C-MET), Pune, the Forest Development Corporation under the Government of Maharashtra, as well as the online processing of examinations for Class III and Class IV staff selection in the regions of Satara, Solapur, Sangli, Kolhapur, and Pune Mahanagar Parivahan Mahamandal Limited (PMPML).
                    <br />
                    Additionally, our team is actively engaged in developing an Electronic Records Management & Tracking system. Moreover, we have successfully designed and implemented a Geographical Information System (GIS)-based decision support system for e-governance comprising of approximately 15 inter-operable modules seamlessly integrated across various urban municipal departments, serving both the Pimpri Chinchwad Municipal Corporation and the Rajkot Municipal Corporation.
                    <br />
                    We have also taken up the independent development of several web modules for the Maharashtra State Agriculture Department. These modules allow for the creation of an Agriculture Management Information System (Agriculture MIS), Online Monitoring of Crop Cutting Operations, Online Monitoring of the State Agriculture Technology Management Agency, Development of an Online Agriculture Technology Institute Management & Training Extension Training Institute in Pune, Online Monitoring of the Agriculture Input Management System for Zilla Parishad, Online Monitoring of Timely Reporting of Agriculture Area in Maharashtra, Online Fruit Tree Valuation System for land acquisition bodies in Maharashtra, Online Monitoring of the Vidarbha Intensive Irrigation Development Program under the Government of Maharashtra, and the creation of an online Maha Farmer Portal to serve all districts in Maharashtra.
                </Typography>
            </Container>
        </Box>
    );
};

export default Content;
