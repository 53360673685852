import React from "react";
import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pattern from "../../Media/Pattern.png";
import phoneIcon from "../../Media/phoneIcon.png";
import FAQImage from "../../Media/FAQImage.png";

export default function FAQSection() {
    return (
        <Box sx={{ mt: 8, py: { xs: 10, md: 10 }, px: { xs: 2, md: 12 }, backgroundColor: "#f4f4f4" }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={6}>
                    <Box sx={{ mb: 4 }}>
                        <Grid mb={2} display={"flex"}>
                            <img height="22px" src={pattern} alt="pattern" />
                            <Typography
                                variant="subtitle2"
                                backgroundColor="#eaeff3"
                                fontWeight="500"
                                px="0.5rem"
                            >
                                FAQ
                            </Typography>
                        </Grid>
                        <Typography
                            variant="h4"
                            sx={{ color: "#1C1F35", fontWeight: 600, mb: 2 }}
                        >
                            Frequently Asked <br /> Questions
                        </Typography>
                    </Box>
                    <Accordion
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: 0,
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: 18, fontWeight: 600, color: "#1C1F35" }}
                            >
                                How can I pay for your logistics services?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184" }}>
                                Leverage agile frameworks to provide a robust synopsis for
                                strategy foster collaborative thinking to further the overall
                                value proposition. Organically grow the holistic world view of
                                disruptive.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: 0,
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: 18, fontWeight: 600, color: "#1C1F35" }}
                            >
                                What payment methods are supported?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184" }}>
                                We support various payment methods including credit cards,
                                PayPal, and direct bank transfers.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: 0,
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: 18, fontWeight: 600, color: "#1C1F35" }}
                            >
                                What options for logistics plans are available?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184" }}>
                                Our logistics plans include express shipping, standard shipping,
                                and overnight delivery options.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        sx={{
                            backgroundColor: "transparent",
                            border: "none",
                            boxShadow: 0,
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                                variant="h6"
                                sx={{ fontSize: 18, fontWeight: 600, color: "#1C1F35" }}
                            >
                                Can I specify a delivery date when ordering?
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184" }}>
                                Yes, you can specify a preferred delivery date during checkout.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} lg={4} sx={{ position: 'relative' }}>
                    <Box
                        sx={{
                            backgroundColor: "#091242",
                            borderRadius: 0,
                            padding: 8,
                            color: "#FFFFFF",
                            zIndex: 2,
                            position: "relative",
                        }}
                    >
                        <Grid mb={2} display={"flex"}>
                            <img height={{ xs: "62px", md: "22px" }} src={pattern} alt="pattern" />
                            <Typography
                                variant="subtitle2"
                                backgroundColor="#1f2753"
                                fontWeight="500"
                                px="0.5rem"
                            >
                                Let's Talk
                            </Typography>
                        </Grid>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                            You Need Any Help? Get Free Consultation
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
                            <Box>
                                <img width="32px" src={phoneIcon} alt="phone" />
                            </Box>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Have Any Questions
                                <br />
                                (00) 112 365 489
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                borderRadius: 0,
                                backgroundColor: "#e88060",
                                color: "#FFFFFF",
                                padding: "12px 24px",
                                fontWeight: 600,
                                fontSize: "16px",
                                textTransform: "none",
                                ":hover": {
                                    backgroundColor: "#FF7043",
                                },
                            }}
                        >
                            Contact Us
                        </Button>
                    </Box>
                    <Box
                        component="img"
                        src={FAQImage}
                        alt="Consultation"
                        sx={{
                            position: "absolute",
                            top: '-40px',
                            right: '-190px',
                            width: "32rem",
                            zIndex: 1,
                            display: { xs: 'none', lg: 'block' },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
