import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

export default function CustomTabs() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="inherit"
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
                sx={{
                    '.MuiTab-root': {
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#091242',
                        backgroundColor: 'transparent',
                        marginRight: '1rem',
                        '&:hover': {
                            backgroundColor: '#0912421A',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#F27C5C',
                            color: 'white',
                        },
                    },
                    '.MuiTabs-flexContainer': {
                        borderBottom: 'none',
                    },
                }}
            >
                <Tab label="Our Approach" />
                <Tab label="Our Vision" />
                <Tab label="Our Mission" />
            </Tabs>
            <Box sx={{ mt: 2 }}>
                {tabValue === 0 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi
                        architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia
                        voluptas...
                    </Typography>
                )}
                {tabValue === 1 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Content for Our Vision tab.
                    </Typography>
                )}
                {tabValue === 2 && (
                    <Typography sx={{ color: '#666C89' }}>
                        Converting knowledge into wealth.
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
