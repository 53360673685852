import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import pattern from "../../Media/Pattern.png";
import AboutIcon1 from "../../Media/AboutIcon1.png";
import AboutIcon2 from "../../Media/AboutIcon2.png";
import AboutIcon3 from "../../Media/AboutIcon3.png";
import AboutIcon4 from "../../Media/AboutIcon4.png";
import AboutIcon5 from "../../Media/AboutIcon5.png";
import AboutIcon6 from "../../Media/AboutIcon6.png";

export default function IncubationServices() {
    const services = [
        { icon: AboutIcon1, title: "Sea Transport Services" },
        { icon: AboutIcon2, title: "Warehousing Services" },
        { icon: AboutIcon3, title: "Air Freight Services" },
        { icon: AboutIcon4, title: "Project & Exhibition" },
        { icon: AboutIcon5, title: "Local Shipping Services" },
        { icon: AboutIcon6, title: "Customer Clearance" },
    ];

    return (
        <Box sx={{ backgroundColor: "#f4f4f4", px: 10, py: 8 }}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <img height="28px" src={pattern} alt="pattern" />
                <Typography
                    variant="h6"
                    backgroundColor="#eaeff3"
                    fontSize={"1rem"}
                    fontWeight="500"
                    px="0.5rem"
                >
                    What We Do
                </Typography>
            </Grid>
            <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="center"
                mt={2}
                mb={6}
            >
                Our Incubation Services
            </Typography>
            <Grid
                container
                mt="2rem"
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "6rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "1rem" },
                }}
            >
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index} >
                        <img src={service?.icon} alt={service?.title} />
                        <Typography variant="h5" mt={2}>
                            {service.title}
                        </Typography>
                        <Typography sx={{ color: "#666C89", mt: 1.5 }}>
                            Following the quality of our service thus having gained trust of
                            our many clients.
                        </Typography>
                    </Grid>
                ))}
            </Grid>
            <Box textAlign="center" mt={6}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#091242",
                        color: "white",
                        padding: "1rem 3rem",
                        fontWeight: 550,
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#5A6C9D",
                        },
                    }}
                >
                    More Works
                </Button>
            </Box>
        </Box>
    );
}
