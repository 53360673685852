import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Link,
    Card,
    CardContent,
    CardMedia,
    Button,
} from "@mui/material";
import membership1 from "../../../Media/membership1.png";
import membership2 from "../../../Media/membership2.png";
import membership3 from "../../../Media/membership3.png";
import membership4 from "../../../Media/membership4.png";
import axios from "axios";
import { truncateText } from "../../../Components/truncateText";


export default function OurMemberships() {

    const [Data, setData] = useState([]);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        axios
            .get("https://api.scitechpark.org.in/association/getall")
            .then((response) => {
                const allData = response.data.result;
                if (Array.isArray(allData)) {
                    const latestData = allData.slice(-4).reverse();
                    setData(latestData);
                } else {
                    console.error("Expected an array but got:", allData);
                }
            })
            .catch((error) => {
                console.error("Error fetching the carousel items:", error);
            });
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "#edf7fe",
                py: 8,
                px: 2,
                color: "#000",
            }}
        >
            <Typography
                variant="h6"
                textAlign="center"
                mb={1.5}
                sx={{ color: "#008bf9" }}
            >
                More Development Endeavours
            </Typography>
            <Typography
                variant="h4"
                textAlign="center"
                mb={6}
                sx={{ fontWeight: "bold", color: "#1c2b78" }}
            >
                Our Memberships
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {Data?.filter((event) => event.Is_deleted === 0)
                    ?.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={2.2} key={index}>
                            <Card
                                sx={{
                                    maxWidth: "100%",
                                    backgroundColor: "#fff",
                                    borderRadius: 0,
                                    color: "#000",
                                    height: "100%",
                                    boxShadow: 3,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={`${docUrl}${item?.Logo}`}
                                    alt={item.Name}
                                    sx={{ height: 140, objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold", color: "#1c2b78" }}
                                    >
                                        {item.Name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ mt: 1, mb: 2, color: "#666666" }}
                                    >
                                        {truncateText(item?.Description, 55)}
                                    </Typography>
                                    <Link href={item.link} color="inherit" underline="none">
                                        <Typography variant="body2" sx={{ color: "#008bf9" }}>
                                            Read More →
                                        </Typography>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
            <Box textAlign="center" mt={6}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#1c2b78",
                        color: "white",
                        padding: "1rem 3rem",
                        fontWeight: 550,
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: 0,
                        ":hover": {
                            backgroundColor: "#5A6C9D",
                        },
                    }}
                    onClick={() => {
                        window.open(`/memberships`, "_self");
                    }}
                >
                    More Memberships
                </Button>
            </Box>
        </Box>
    );
}
