import React from 'react'
import Banner from '../../Components/Banner'
import Content from './Content'

const GrowthLab = () => {
  return (
    <>
    <Banner title={"Growth Lab"} />
    <Content />
    </>
  )
}

export default GrowthLab;