import React from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import ShapesLeft from "../../Media/ShapesLeft.png";
import Logo from "../../Media/Logo.png";
// import contact from "../../Media/helplineContact.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";

const Footer = () => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "40vh",
                color: '#b3bbca'
                // color: "#232536",
                // overflowX: "hidden",
            }}
        >
            <Grid
                container
                py={{ xs: "3rem", md: "4rem" }}
                sx={{ display: "flex" }}
                justifyContent={"space-around"}
                alignItems={"center"}
                gap={{ xs: "2rem", lg: "0rem" }}
                backgroundColor='#1c2b78'
            >
                <Grid
                    item
                    width={{ xs: "100%", sm: "30rem", md: "30rem", lg: "25rem" }}
                    px={{ xs: "1.5rem", sm: "3.8rem" }}
                >
                    <Box sx={{ backgroundColor: "white", padding: 1.5 }}>
                        <img
                            src={Logo}
                            alt=""
                            style={{ width: "100%", objectFit: "contain" }}
                        />
                    </Box>

                    <Typography
                        variant="h5"
                        m="2rem 0"
                        textAlign={"left"}
                        fontSize={"14px"}
                        fontWeight={"500"}
                        lineHeight={'1.5rem'}

                    >
                        Science and Technology Park, Pune | Savitribai Phule Pune University Campus, Ganeshkhind Road, Pune- 411 007, Maharashtra, India
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <IconButton
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#b3bbca" }}
                        >
                            <Facebook />
                        </IconButton>
                        <IconButton
                            href="https://www.twitter.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#b3bbca" }}
                        >
                            <Twitter />
                        </IconButton>
                        <IconButton
                            href="https://www.instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#b3bbca" }}
                        >
                            <Instagram />
                        </IconButton>
                        <IconButton
                            href="https://www.linkedin.com/company/pixaflip/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: "#b3bbca" }}
                        >
                            <LinkedIn />
                        </IconButton>
                    </Box>
                </Grid>

                <Grid item>
                    <Grid
                        container
                        display="flex"
                        spacing={4}
                    >
                        <Grid item>
                            {/* <Typography
                                variant="h1"
                                m="0 1rem"
                                textAlign={"left"}
                                fontSize={"0.8rem"}
                                fontWeight={"600"}
                            >
                                Key Pages
                            </Typography> */}
                            <List>
                                {[
                                    "Home",
                                    "Incubation",
                                    "CIII",
                                    "News & Media",
                                ].map((text) => (
                                    <ListItem key={text}>
                                        <ListItemText
                                            primary={text}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    color: "#b3bbca",
                                                    lineHeight: "0.5rem",
                                                    cursor: "pointer",
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                        {/* <Grid item>
                            <Typography
                                variant="h1"
                                m="0 1rem"
                                textAlign={"left"}
                                fontSize={"0.8rem"}
                                fontWeight={"600"}
                            >
                                Domains
                            </Typography> 
                             <List>
                                {[
                                    "Development",
                                    "AWS / Azure",
                                    "Consulting",
                                    "Information Technology",
                                ].map((text) => (
                                    <ListItem key={text}>
                                        <ListItemText
                                            primary={text}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    color: "#b3bbca",
                                                    lineHeight: "0.5rem",
                                                    cursor: "pointer",
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List> 
                        </Grid> */}


                        <Grid item>
                            {/* <Typography
                                variant="h1"
                                m="0 1rem"
                                textAlign={"left"}
                                fontSize={"0.8rem"}
                                fontWeight={"600"}
                            >
                                Products
                            </Typography> */}
                            <List>
                                {[
                                    "About Us",
                                    "Privacy Policy",
                                    "Terms of use",
                                ].map((text) => (
                                    <ListItem key={text}>
                                        <ListItemText
                                            primary={text}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    color: "#b3bbca",
                                                    lineHeight: "0.5rem",
                                                    cursor: "pointer",
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

            <Grid
                container
                height={{ xs: "auto", md: "3rem" }}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                gap={{ xs: "2rem", md: "2rem", lg: "14rem" }}
                sx={{ backgroundColor: "#152265", py: { xs: 2, md: 0 } }}
            >
                <Typography
                    variant="h1"
                    fontSize="14px"
                    lineHeight="2rem"
                    sx={{ ml: 1 }}
                    color={'#b3bbca'}
                >
                    Content Owned, Maintained and Updated by © 2023 SCIENCE AND TECHNOLOGY PARK, PUNE - ALL RIGHTS RESERVED
                </Typography>

            </Grid>
        </Box>
    );
};

export default Footer;
