import React from "react";
import { Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import pattern from "../../Media/Pattern.png";
import ClientLogo from "../../Media/ClientLogo.png";

const clients = [
    { id: 1, name: "Studio Green", image: "/path/to/studiogreen.png" },
    { id: 2, name: "Norto", image: "/path/to/norto.png" },
    { id: 3, name: "Points One", image: "/path/to/pointone.png" },
    { id: 4, name: "Out of the Sandbox", image: "/path/to/outofthesandbox.png" },
];

export default function ClientsSection() {
    return (
        <Box pt={4} pb={{ xs: 0, md: 2 }}>
            <Box sx={{ py: { xs: 2, md: 8 }, px: { xs: 2, md: 22 }, }}>
                <Grid mb={2} display={"flex"}>
                    <img height="22px" src={pattern} alt="pattern" />
                    <Typography
                        variant="subtitle2"
                        backgroundColor="#eaeff3"
                        fontWeight="500"
                        px="0.5rem"
                    >
                        Partners
                    </Typography>
                </Grid>
                <Typography
                    variant="h4"
                    sx={{
                        color: "#1C1F35",
                        textAlign: "left",
                        fontWeight: "bold",
                        mb: 3,
                    }}
                >
                    Our Esteemed Clients
                </Typography>
            </Box >
            <Grid container>
                <img width={'100%'} src={ClientLogo} alt="ClientLogo" />
            </Grid>
        </Box>
    );
}
