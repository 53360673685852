import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sectorDataAndSLug from "../../Data/SectorData";
import { Box, Grid, Typography } from "@mui/material";
import GrowthLabCard from "./GrowthLabCard";
import Banner from "../../Components/Banner";

const BusinessIncubationBySectorPage = () => {
    const { sector } = useParams();
    const [sectorData, setSectorData] = useState([]);

    const sectorInfo = sectorDataAndSLug.find((item) => item.slug === sector);
    useEffect(() => {
        console.log("sectorInfo", sectorInfo);
        if (sectorInfo) {
            const sectorName = sectorInfo.name;

            axios
                .post("https://api.scitechpark.org.in/growth/getall", {
                    Sector: sectorName,
                })
                .then((response) => {
                    const allSector = response?.data?.result;
                    if (Array.isArray(allSector)) {
                        const latestData = allSector?.reverse();
                        setSectorData(latestData);
                    } else {
                        console.error("Expected an array but got:", allSector);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching the data", error);
                });
        } else {
            console.error("Sector not found for slug:", sector);
        }
    }, []);

    return (
        <Box>
            {
                sectorInfo?.name ? <Banner title={sectorInfo?.name} /> : null
            }

            <Box sx={{ py: 12, backgroundColor: "#edf7fe", color: "#1C1F35" }}>
                {sectorData.length > 0 ? (
                    sectorData.map((item, index) => (
                        <GrowthLabCard key={index} company={item} />
                    ))
                ) : (
                    <Grid
                        style={{
                            display: "grid",
                            placeItems: "center",
                            py: 50
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt=""
                        />
                        <Typography >
                            No data available for this sector.
                        </Typography>
                    </Grid>
                )}
            </Box>
        </Box>
    );
};

export default BusinessIncubationBySectorPage;
