import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Link as MuiLink, Popover } from "@mui/material";
import Logo from "../../Media/Logo.png";
import { Link as RouterLink, useLocation } from "react-router-dom";

const pages = [
    {
        label: "Home",
        href: "/",
        subpages: [{ label: "About us", href: "/about-us" }],
    },
    {
        label: "Domains",
        href: "#domains",
        subpages: [
            { label: "Business Incubation", href: "/business-incubation" },
            { label: "Skill Training", href: "/skill-training" },
            { label: "Geoinformatics", href: "geoinformatics" },
            { label: "CSR", href: "/csr" },
        ],
    },
    {
        label: "Business Incubation",
        href: "#businessIncubation",
        subpages: [
            { label: "DST NIDHI", href: "/dst-nidhi" },
            { label: "Startup India", href: "/startup-india" },
            { label: "Growth Lab", href: "/growth-lab" },
            { label: "Products Innovation", href: "/products-innovation" },
            { label: "CIII Centers", href: "/ciii-centers" },
            { label: "Awards and recognition", href: "/awards" },
        ],
    },
    {
        label: "Events",
        href: "/events",
    },
    { label: "News & Media", to: "/news-and-media" },
    {
        label: "Donation",
        href: "#donation",
        subpages: [
            { label: "STP Donations", href: "/stp-donations" },
            { label: "Green Donations", href: "/green-donations" },
        ],
    },
    { label: "Contact Us →", to: "/contact-us" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Navbar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElSubMenu, setAnchorElSubMenu] = React.useState({});
    const [hoveredIndex, setHoveredIndex] = React.useState(null);
    const [anchorElMobileSubMenu, setAnchorElMobileSubMenu] = React.useState({});

    const location = useLocation();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setAnchorElSubMenu({});
    };

    const handleOpenSubMenu = (event, index) => {
        setAnchorElSubMenu((prev) => {
            const newState = {};
            Object.keys(prev).forEach((key) => {
                if (key !== index.toString()) {
                    newState[key] = null;
                }
            });
            newState[index] = event.currentTarget;
            return newState;
        });
        setHoveredIndex(index);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseSubMenu = () => {
        setAnchorElSubMenu({});
        setHoveredIndex(null);
    };

    const handleOpenMobileSubMenu = (event, index) => {
        setAnchorElMobileSubMenu((prev) => ({
            ...prev,
            [index]: event.currentTarget,
        }));
    };

    const handleCloseMobileSubMenu = (index) => {
        setAnchorElMobileSubMenu((prev) => ({
            ...prev,
            [index]: null,
        }));
    };

    const isActive = (href, to) => {
        if (to) return location.pathname === to;
        if (href && href !== "#") return location.pathname === href;
        return false;
    };

    const activeStyle = {
        color: "#008bf9",
        borderBottom: "2px solid #008bf9",
    };

    const renderSubMenu = (subpages, parentIndex, isMobile = false) => {
        return subpages.map((subpage, subIndex) => {
            const currentIndex = `${parentIndex}-${subIndex}`;
            return (
                <MenuItem
                    key={currentIndex}
                    onClick={
                        isMobile
                            ? (event) => handleOpenMobileSubMenu(event, currentIndex)
                            : handleCloseNavMenu
                    }
                >
                    {subpage?.to ? (
                        <Typography textAlign="center">
                            <RouterLink
                                to={subpage?.to}
                                style={{ textDecoration: "none", color: "inherit" }}
                            >
                                {subpage?.label}
                            </RouterLink>
                        </Typography>
                    ) : (
                        <Typography textAlign="center">
                            <MuiLink
                                href={subpage?.href ?? "#"}
                                style={{ textDecoration: "none", color: "inherit" }}
                            >
                                {subpage?.label}
                            </MuiLink>
                        </Typography>
                    )}
                    {subpage.subpages &&
                        (isMobile ? (
                            <Menu
                                id={Menu - `${currentIndex}`}
                                anchorEl={anchorElMobileSubMenu[currentIndex]}
                                keepMounted
                                open={Boolean(anchorElMobileSubMenu[currentIndex])}
                                onClose={() => handleCloseMobileSubMenu(currentIndex)}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                {renderSubMenu(subpage.subpages, currentIndex, true)}
                            </Menu>
                        ) : (
                            <Popover
                                open={Boolean(anchorElSubMenu[currentIndex])}
                                anchorEl={anchorElSubMenu[currentIndex]}
                                onClose={() => handleCloseSubMenu()}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <Box>{renderSubMenu(subpage.subpages, currentIndex)}</Box>
                            </Popover>
                        ))}
                </MenuItem>
            );
        });
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: "white",
                top: { xs: 60, md: 35 },
                zIndex: 1090,
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        onClick={() => {
                            window.open("/", "_self");
                        }}
                        p={{ lg: "1rem 10rem 1rem 9rem" }}
                        sx={{
                            display: { xs: "none", md: "flex" },
                            width: { xs: "auto", md: "28rem" },
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src={Logo}
                            alt=""
                            style={{ width: "100%", objectFit: "contain" }}
                        />
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: "#232536" }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page, index) => (
                                <div key={index}>
                                    <MenuItem
                                        onClick={
                                            page.subpages
                                                ? (event) => handleOpenMobileSubMenu(event, index)
                                                : handleCloseMobileSubMenu
                                        }
                                        sx={{
                                            my: 2,
                                            color: "black",
                                            display: "block",
                                            "&:hover": activeStyle,
                                        }}
                                        style={isActive(page.href, page.to) ? activeStyle : {}}
                                    >
                                        {page?.to ? (
                                            <Typography textAlign="center">
                                                <RouterLink
                                                    to={page?.to}
                                                    style={{ textDecoration: "none", color: "inherit" }}
                                                >
                                                    {page?.label}
                                                </RouterLink>
                                            </Typography>
                                        ) : (
                                            <Typography textAlign="center">
                                                <MuiLink
                                                    href={page?.href ?? "#"}
                                                    style={{ textDecoration: "none", color: "inherit" }}
                                                >
                                                    {page?.label}
                                                </MuiLink>
                                            </Typography>
                                        )}
                                    </MenuItem>
                                    {page.subpages && (
                                        <Menu
                                            id={Menu - `${index}`}
                                            anchorEl={anchorElMobileSubMenu[index]}
                                            keepMounted
                                            open={Boolean(anchorElMobileSubMenu[index])}
                                            onClose={() => handleCloseMobileSubMenu(index)}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                            }}
                                        >
                                            {renderSubMenu(page.subpages, index, true)}
                                        </Menu>
                                    )}
                                </div>
                            ))}
                        </Menu>
                    </Box>

                    <Grid
                        container
                        display="flex"
                        justifyItems={"center"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <Box
                                onClick={() => {
                                    window.open("/", "_self");
                                }}
                                sx={{
                                    display: { xs: "flex", md: "none" },
                                    width: "auto",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src={Logo}
                                    alt=""
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: "none", md: "flex" },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <Box
                                        // aria-owns={Boolean(anchorElSubMenu[index]) ? 'mouse-over-popover' : undefined}
                                        // aria-haspopup="true"
                                        key={index}
                                        onMouseEnter={(event) => handleOpenSubMenu(event, index)}
                                        onMouseLeave={handleCloseSubMenu}
                                    >
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            sx={{
                                                borderRadius: 0,
                                                py: 2.9,
                                                color: "black",
                                                display: "block",
                                                position: "relative",
                                                "&::after": {
                                                    content: '""',
                                                    position: "absolute",
                                                    bottom: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "2px",
                                                    backgroundColor: "#008bf9",
                                                    transform: "scaleX(0)",
                                                    transition: "transform 0.3s ease-in-out",
                                                },
                                                "&:hover::after": {
                                                    transform: "scaleX(1)",
                                                },
                                                ...(hoveredIndex === index ||
                                                    isActive(page?.href, page?.to)
                                                    ? {
                                                        color: "#008bf9",
                                                        "&::after": {
                                                            transform: "scaleX(1)",
                                                        },
                                                    }
                                                    : {}),
                                            }}
                                            style={
                                                isActive(page?.href || page?.to) ? activeStyle : {}
                                            }
                                        >
                                            {page?.to ? (
                                                <RouterLink
                                                    to={page?.to}
                                                    style={{ textDecoration: "none", color: "inherit" }}
                                                >
                                                    {page.label}
                                                </RouterLink>
                                            ) : (
                                                <MuiLink
                                                    href={page?.href ?? "#"}
                                                    style={{ textDecoration: "none", color: "inherit" }}
                                                >
                                                    {page?.label}
                                                </MuiLink>
                                            )}
                                        </Button>
                                        {page.subpages && (
                                            <Popover
                                                id="mouse-over-popover"
                                                open={Boolean(anchorElSubMenu[index])}
                                                anchorEl={anchorElSubMenu[index]}
                                                onClose={handleCloseSubMenu}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                                PaperProps={{
                                                    onMouseEnter: () => setHoveredIndex(index),
                                                    onMouseLeave: handleCloseSubMenu,
                                                    sx: {
                                                        mt: 0,
                                                        opacity: 0,
                                                        transition: "opacity 0.3s ease-in-out",
                                                        "&.MuiPopover-paper": {
                                                            opacity: 1,
                                                        },
                                                    },
                                                }}
                                                disableRestoreFocus
                                            >
                                                <Box>{renderSubMenu(page.subpages, index)}</Box>
                                            </Popover>
                                        //     <Menu
                                        //     id={`menu-${index}`}
                                        //     anchorEl={anchorElSubMenu[index]}
                                        //     open={Boolean(anchorElSubMenu[index])}
                                        //     onClose={() => handleCloseSubMenu(index)}
                                        //     MenuListProps={{
                                        //         onMouseLeave: () => handleCloseSubMenu(index),
                                        //     }}
                                        //     anchorOrigin={{
                                        //         vertical: "bottom",
                                        //         horizontal: "left",
                                        //     }}
                                        //     transformOrigin={{
                                        //         vertical: "top",
                                        //         horizontal: "left",
                                        //     }}
                                        // >
                                        //     {renderSubMenu(page.subpages, index)}
                                        // </Menu>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ flexGrow: 0 }}>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;