import React, { useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Link,
    Card,
    List,
    ListItem,
    ListItemText,
    Divider,
} from "@mui/material";
import scitechCSR from "../../../Media/scitechCSR.png";

const menuItems = [
    "Open Source and Open Platform Technology",
    "Renewable Energy and Clean Technology",
    "Pharma and Biotechnology",
    "Agri and Food Processing Technology",
    "Mobile Computing",
    "IT and IT Enabled Services",
    "Project Management",
    "Data Centers",
    "Social Incubation",
    "Marine Services",
    "Cyber Security",
    "GeoInformatics",
    "Education",
    "Electronics and Telecommunication",
];


const slugs = {
    "Open Source and Open Platform Technology": "open-source-platform",
    "Renewable Energy and Clean Technology": "renewable-energy-clean-tech",
    "Pharma and Biotechnology": "pharma-biotech",
    "Agri and Food Processing Technology": "agri-food-processing",
    "Mobile Computing": "mobile-computing",
    "IT and IT Enabled Services": "it-ites",
    "Project Management": "project-management",
    "Data Centers": "data-centers",
    "Social Incubation": "social-incubation",
    "Marine Services": "marine-services",
    "Cyber Security": "cyber-security",
    "GeoInformatics": "geoinformatics",
    Education: "education",
    "Electronics and Telecommunication": "electronics-telecom",
};


const content = {
    "Open Source and Open Platform Technology": `This section supports startups developing open-source solutions and open platform technologies, driving innovation in software and hardware ecosystems.`,

    "Renewable Energy and Clean Technology": `Focused on fostering startups that innovate in the renewable energy sector, this program promotes clean technology solutions for a sustainable future.`,

    "Pharma and Biotechnology": `Incubating startups in the fields of pharmaceuticals and biotechnology, with a focus on groundbreaking research and innovations in healthcare and medicine.`,

    "Agri and Food Processing Technology": `Supporting startups in agriculture and food processing technology, aiming to enhance food security, sustainability, and innovation in farming practices.`,

    "Mobile Computing": `This section focuses on mobile computing advancements, incubating startups that innovate in mobile technology and mobile-first solutions.`,

    "IT and IT Enabled Services": `Startups focused on information technology and IT-enabled services are provided with resources to develop cutting-edge software and digital solutions.`,

    "Project Management": `Helping startups in project management, offering support for developing tools and techniques that improve project execution and efficiency.`,

    "Data Centers": `Startups working in data center technologies are incubated to develop scalable and efficient solutions for managing large-scale data and cloud infrastructures.`,

    "Social Incubation": `This program supports social entrepreneurship, incubating startups focused on creating social impact and sustainable community development solutions.`,

    "Marine Services": `Focused on marine technology, this section supports startups working on innovations in maritime transport, marine biology, and oceanography.`,

    "Cyber Security": `Providing incubation for startups working in cyber security, with a focus on developing solutions to protect data, systems, and networks from evolving threats.`,

    "GeoInformatics": `Startups in the geoinformatics field are incubated here, developing geographic information systems (GIS) and spatial data solutions for various applications.`,

    "Education": `This section supports startups innovating in education, with a focus on creating new methods, platforms, and technologies to improve learning outcomes.`,

    "Electronics and Telecommunication": `Incubating startups in the electronics and telecommunication sectors, driving innovation in communication technologies and electronic devices.`,
};


export default function BusinessIncubationContent() {
    const [selectedTab, setSelectedTab] = useState("Open Source and Open Platform Technology");

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={4} md={3.2} lg={2.5}>
                <Card
                    sx={{
                        boxShadow: 3,
                        borderRadius: "0",
                        width: { xs: "auto", md: "250px" },
                        backgroundColor: "#fff",
                        height: "100%",
                        maxHeight: "400px",
                        overflowY: "auto",
                    }}
                >
                    <List>
                        {menuItems.map((item, idx) => (
                            <div key={idx}>
                                <ListItem disableGutters>
                                    <ListItemText>
                                        <Link
                                            color="inherit"
                                            underline="none"
                                            onClick={() => setSelectedTab(item)}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    my: -1.6,
                                                    backgroundColor:
                                                        selectedTab === item ? "#008bf9" : "transparent",
                                                    color: selectedTab === item ? "white" : "#999999",
                                                    padding: "1rem 1rem",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </Link>
                                    </ListItemText>
                                </ListItem>
                                {idx !== menuItems.length - 1 && <Divider />}
                            </div>
                        ))}
                    </List>
                </Card>

            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={6}>
                <Card
                    sx={{
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "0",
                        boxShadow: 3,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "center", md: "flex-start" }}
                    >
                        <Grid item xs={12} md={6}>
                            <Box sx={{ p: 3 }}>
                                <Typography
                                    variant="h6"
                                    sx={{ color: "#1c2b78", fontWeight: "bold" }}
                                >
                                    {selectedTab}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "#666666",
                                        fontSize: "0.89rem",
                                        fontWeight: "500",
                                        lineHeight: "1.3rem",
                                        letterSpacing: "1px",
                                        mt: 2,
                                    }}
                                >
                                    {content[selectedTab]}
                                </Typography>
                                <Link
                                    onClick={() => {
                                        const slug = slugs[selectedTab];
                                        if (slug) {
                                            window.open(`/growth-lab/${slug}`, "_self");
                                        }
                                    }}
                                    color="inherit"
                                    underline="none"
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "#0275d8", mt: 2, cursor: "pointer" }}
                                    >
                                        Read More →
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 3,
                                mt: 4,
                            }}
                        >
                            <img
                                src={scitechCSR}
                                alt="Scitech CSR"
                                style={{ maxWidth: "100%", height: "auto" }}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}

